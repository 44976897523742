import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/main.css'
import Element from "element-ui";
import './assets/css/element.scss'

Vue.config.productionTip = false

Vue.use(Element, { size: "small" });

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
