import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export const routes = [
    {
        path: "/solution",
        component: () => import("@/views/solution/solution"),
        name: "solution",
        meta: {
            title: "专属方案",
            icon: "i i-shipinjianji",
            noCache: true,
            zIndex: 2,
        },
    },
];

const router = new Router({
    routes,
});

router.beforeEach((to, from, next) => {
    console.log('router-----', to)
    if (to.name === 'solution') {
        localStorage.setItem('token', to.query.token || '')
    }
    next();
})
export default router;
